import dayjs from "dayjs";
import CreditApplicationRep from "reps/CreditApplicationRep";

import isClosedBooksMonthRecentEnough from "./isClosedBooksMonthRecentEnough";
import isFirstSaleMonthEarlyEnough from "./isFirstSaleMonthEarlyEnough";

const hasPassedApplicationRequirements = (
  creditApplication: CreditApplicationRep.Complete
): boolean => {
  const { userProvidedDetails } = creditApplication;

  const isValidFirstSale =
    userProvidedDetails?.firstSaleAt &&
    isFirstSaleMonthEarlyEnough(dayjs(userProvidedDetails.firstSaleAt));

  const isValidBooksClosed =
    userProvidedDetails?.booksClosedAt &&
    userProvidedDetails.booksClosedAt.type === CreditApplicationRep.BooksClosedAtType.Exactly &&
    isClosedBooksMonthRecentEnough(dayjs(userProvidedDetails.booksClosedAt.yearMonth));

  return Boolean(isValidFirstSale && isValidBooksClosed);
};

export default hasPassedApplicationRequirements;
