import dayjs, { Dayjs } from "dayjs";

export const REQUIRED_CLOSED_BOOKS_DAYS = 75;

const isClosedBooksMonthRecentEnough = (inputDate: Dayjs): boolean => {
  const now = dayjs();
  const latestValidDate = now.subtract(REQUIRED_CLOSED_BOOKS_DAYS, "day");

  // Check if the input date's month is the same or after the expected date's month
  return inputDate.isAfter(latestValidDate.subtract(1, "month"), "month");
};

export default isClosedBooksMonthRecentEnough;
