import { useSuspenseQuery } from "@tanstack/react-query";
import CreditApplicationRep from "reps/CreditApplicationRep";

import useCreditApplicationsQueryOptions from "./useCreditApplicationsQueryOptions";

const useMostRecentCreditApplication = (): CreditApplicationRep.Complete | undefined => {
  const { data: creditApplications } = useSuspenseQuery(useCreditApplicationsQueryOptions());

  return creditApplications[0];
};

export default useMostRecentCreditApplication;
