import { selector } from "recoil";
import jwtState from "state/auth/jwt";
import paymentAmountState from "state/payments/paymentAmount";
import paymentIdempotencyKeyState from "state/payments/paymentIdempotencyKey";
import { isLastMfaValid, isLastMfaValidForLargePayment } from "utils/auth";

const LARGE_PAYMENT_THRESHOLD = 250_000_00;

const isMfaCompleteState = selector<boolean>({
  key: "auth/isMfaComplete",
  get: async ({ get }) => {
    const jwt = get(jwtState);
    get(paymentIdempotencyKeyState);
    const paymentAmount = get(paymentAmountState);
    const isLargePayment = paymentAmount >= LARGE_PAYMENT_THRESHOLD;

    if (isLargePayment) {
      return isLastMfaValidForLargePayment(jwt);
    }

    return isLastMfaValid(jwt);
  },

  // Fixes an annoying bug where `isLargePayment` would not be re-evaluated
  // See https://highbeamco.slack.com/archives/C02M3GGQPHC/p1687798178473469
  // eslint-disable-next-line camelcase
  cachePolicy_UNSTABLE: { eviction: "most-recent" },
});

export default isMfaCompleteState;
