import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * Wraps useSearchParams to specify a single boolean value and preserves other search params on update.
 */
const useSearchParamBoolean = (
  key: string,
  defaultValue: boolean
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const defaultValueAsString = defaultValue?.toString();

  const [searchParams, setSearchParams] = useSearchParams({
    ...(defaultValueAsString && { [key]: defaultValueAsString }),
  });

  const value = useMemo(() => {
    return searchParams.get(key) === "true";
  }, [searchParams, key]);

  const setValue = useCallback(
    (setStateAction: SetStateAction<boolean>) => {
      // It's important for us to redefine this here to prevent overwriting params if multiple setters get called at once.
      const currentParams = new URLSearchParams(window.location.search);

      if (typeof setStateAction === "function") {
        const valueResult = setStateAction(Boolean(currentParams.get(key)) ?? defaultValue);
        return setSearchParams({
          ...Object.fromEntries(currentParams.entries()),
          [key]: valueResult.toString(),
        });
      }

      return setSearchParams({
        ...Object.fromEntries(currentParams.entries()),
        [key]: setStateAction.toString(),
      });
    },
    [key, defaultValue, setSearchParams]
  );

  return [value, setValue];
};

export default useSearchParamBoolean;
