import BusinessAddressApi from "api/BusinessAddressApi";
import BusinessDetailsApi from "api/BusinessDetailsApi";
import env from "env";

import ActionItemApi from "./ActionItemApi";
import ApEmailAliasApi from "./ApEmailAliasApi";
import AuditTrailApi from "./AuditTrailApi";
import AuthorizedUserApi from "./AuthorizedUserApi";
import BankAccountApi from "./BankAccountApi";
import BankApplicationApi from "./BankApplicationApi";
import BillActionApi from "./BillActionApi";
import BillApi from "./BillApi";
import BillCommentApi from "./BillCommentApi";
import BillDocumentActionApi from "./BillDocumentActionApi";
import BillDocumentApi from "./BillDocumentApi";
import BillEmailApi from "./BillEmailApi";
import BillSuggestedPayeeApi from "./BillSuggestedPayeeApi";
import BillSummaryApi from "./BillSummaryApi";
import BusinessApi from "./BusinessApi";
import BusinessMemberApi from "./BusinessMemberApi";
import BusinessOwnerApi from "./BusinessOwnerApi";
import CapitalAccountApi from "./CapitalAccountApi";
import CapitalDrawdownApprovalApi from "./CapitalDrawdownApprovalApi";
import CapitalTransactionsApi from "./CapitalTransactionsApi";
import CardApi from "./CardApi";
import ChargeCardApi from "./ChargeCardApi";
import ChargeCardTransactionsApi from "./ChargeCardTransactionsApi";
import CounterpartyAliasManagementApi from "./CounterpartyAliasManagementApi";
import CounterpartyReportApi from "./CounterpartyReportApi";
import CreditApplicationApi from "./CreditApplicationApi";
import CreditComparisonApi from "./CreditComparisonApi";
import DailyPlReportApi from "./DailyPlReportApi";
import DailyShopifySummaryApi from "./DailyShopifySummaryApi";
import DebitCardApi from "./DebitCardApi";
import DocumentsApi from "./DocumentsApi";
import DocumentV2ActionApi from "./DocumentV2ActionApi";
import DocumentV2Api from "./DocumentV2Api";
import GeneralPaymentMetadataApi from "./GeneralPaymentMetadataApi";
import HighbeamBaseApi from "./HighbeamBaseApi";
import CategorizationRuleApi from "./Insights/CategorizationRuleApi";
import CategorizedResourceApi from "./Insights/CategorizedResourceApi";
import SubcategoryApi from "./Insights/SubcategoryApi";
import VendorListApi from "./Insights/VendorListApi";
import InsightsCategoryApi from "./InsightsCategoryApi";
import InsightsSheetApi from "./InsightsSheetApi";
import InstitutionApi from "./InstitutionApi";
import IntercomApi from "./IntercomApi";
import InternationalBankAccountApi from "./InternationalBankAccountApi";
import LeadApi from "./LeadApi";
import LineOfCreditAgreementApi from "./LineOfCreditAgreementApi";
import LineOfCreditApi from "./LineOfCreditApi";
import LineOfCreditBalanceRecoveryApi from "./LineOfCreditBalanceRecoveryApi";
import LineOfCreditInterestFeeApi from "./LineOfCreditInterestFeeApi";
import LineOfCreditTransactionsApi from "./LineOfCreditTransactionsApi";
import PayeeApi from "./PayeeApi";
import PaymentActionApi from "./PaymentActionApi";
import PaymentApi from "./PaymentApi";
import PaymentApiV2 from "./PaymentApiV2";
import PaymentDetailsApi from "./PaymentDetailsApi";
import PlaidApi from "./PlaidApi";
import ReferralLinkApi from "./ReferralLinkApi";
import RutterBalanceApi from "./RutterBalanceApi";
import RutterConnectionApi from "./RutterConnectionApi";
import RutterPayoutApi from "./RutterPayoutApi";
import ShopifyBalanceApi from "./ShopifyBalanceApi";
import ShopifyConnectionApi from "./ShopifyConnectionApi";
import ShopifyPayoutApi from "./ShopifyPayoutApi";
import TransactionApi from "./TransactionApi";
import TransactionCategorizationMatcherApi from "./TransactionCategorizationMatcherApi";
import UnitCoCustomerTokenApi from "./UnitCoCustomerTokenApi";
import UserApi from "./UserApi";
import UserInvitationApi from "./UserInvitationApi";
import UserNotificationSettingsApi from "./UserNotificationSettingsApi";
import UserRoleApi from "./UserRoleApi";
import UserRoleMembershipApi from "./UserRoleMembershipApi";

/**
 * Enables interaction with the Highbeam backend. In order to avoid an excessively large file, the API
 * interaction is broken down into delegate APIs that all use a common shared base.
 */
export default class HighbeamApi {
  readonly actionItem: ActionItemApi;
  readonly apEmailAlias: ApEmailAliasApi;
  readonly auditTrail: AuditTrailApi;
  readonly authorizedUser: AuthorizedUserApi;
  readonly bankAccount: BankAccountApi;
  readonly bankApplication: BankApplicationApi;
  readonly bill: BillApi;
  readonly billAction: BillActionApi;
  readonly billComment: BillCommentApi;
  readonly billDocument: BillDocumentApi;
  readonly billDocumentAction: BillDocumentActionApi;
  readonly billEmail: BillEmailApi;
  readonly billSuggestedPayee: BillSuggestedPayeeApi;
  readonly billSummary: BillSummaryApi;
  readonly business: BusinessApi;
  readonly businessAddress: BusinessAddressApi;
  readonly businessDetails: BusinessDetailsApi;
  readonly businessMember: BusinessMemberApi;
  readonly businessOwner: BusinessOwnerApi;
  readonly capitalAccount: CapitalAccountApi;
  readonly capitalDrawdownApproval: CapitalDrawdownApprovalApi;
  readonly capitalTransactions: CapitalTransactionsApi;
  readonly card: CardApi;
  readonly categorizationRule: CategorizationRuleApi;
  readonly categorizedResource: CategorizedResourceApi;
  readonly counterpartyAliasManagement: CounterpartyAliasManagementApi;
  readonly creditApplication: CreditApplicationApi;
  readonly chargeCard: ChargeCardApi;
  readonly chargeCardTransactions: ChargeCardTransactionsApi;
  readonly counterpartyReport: CounterpartyReportApi;
  readonly creditComparison: CreditComparisonApi;
  readonly dailyPlReport: DailyPlReportApi;
  readonly dailyShopifySummary: DailyShopifySummaryApi;
  readonly debitCard: DebitCardApi;
  readonly documentV2: DocumentV2Api;
  readonly documentV2Action: DocumentV2ActionApi;
  readonly documents: DocumentsApi;
  readonly generalPaymentMetadata: GeneralPaymentMetadataApi;
  readonly insightsCategory: InsightsCategoryApi;
  readonly insightsSheet: InsightsSheetApi;
  readonly institution: InstitutionApi;
  readonly intercom: IntercomApi;
  readonly internationalBankAccount: InternationalBankAccountApi;
  readonly lead: LeadApi;
  readonly lineOfCredit: LineOfCreditApi;
  readonly lineOfCreditBalanceRecovery: LineOfCreditBalanceRecoveryApi;
  readonly lineOfCreditAgreement: LineOfCreditAgreementApi;
  readonly lineOfCreditInterestFee: LineOfCreditInterestFeeApi;
  readonly lineOfCreditTransactions: LineOfCreditTransactionsApi;
  readonly payee: PayeeApi;
  readonly payment: PaymentApi;
  readonly paymentAction: PaymentActionApi;
  readonly paymentV2: PaymentApiV2;
  readonly paymentDetails: PaymentDetailsApi;
  readonly plaid: PlaidApi;
  readonly referralLink: ReferralLinkApi;
  readonly rutterBalance: RutterBalanceApi;
  readonly rutterConnection: RutterConnectionApi;
  readonly rutterPayout: RutterPayoutApi;
  readonly shopifyBalance: ShopifyBalanceApi;
  readonly shopifyConnection: ShopifyConnectionApi;
  readonly shopifyPayouts: ShopifyPayoutApi;
  readonly subcategories: SubcategoryApi;
  readonly transaction: TransactionApi;
  readonly transactionCategorizationMatcher: TransactionCategorizationMatcherApi;
  readonly unitCoCustomerToken: UnitCoCustomerTokenApi;
  readonly user: UserApi;
  readonly userInvitation: UserInvitationApi;
  readonly userNotificationSettings: UserNotificationSettingsApi;
  readonly userRole: UserRoleApi;
  readonly userRoleMembership: UserRoleMembershipApi;
  readonly vendorList: VendorListApi;

  constructor(getJwt: () => Promise<string | undefined>) {
    const v1Api = new HighbeamBaseApi(env.HIGHBEAM_API_ORIGIN_V1, getJwt);
    const v2Api = new HighbeamBaseApi(env.HIGHBEAM_API_ORIGIN_V2, getJwt);

    // TODO: Generate the following from backend code
    this.actionItem = new ActionItemApi(v1Api);
    this.apEmailAlias = new ApEmailAliasApi(v2Api);
    this.auditTrail = new AuditTrailApi(v2Api);
    this.authorizedUser = new AuthorizedUserApi(v1Api);
    this.bankAccount = new BankAccountApi(v1Api);
    this.bankApplication = new BankApplicationApi(v1Api);
    this.bill = new BillApi(v2Api);
    this.billAction = new BillActionApi(v2Api);
    this.billComment = new BillCommentApi(v2Api);
    this.billDocument = new BillDocumentApi(v2Api);
    this.billDocumentAction = new BillDocumentActionApi(v2Api);
    this.billEmail = new BillEmailApi(v2Api);
    this.billSuggestedPayee = new BillSuggestedPayeeApi(v2Api);
    this.billSummary = new BillSummaryApi(v2Api);
    this.business = new BusinessApi(v1Api);
    this.businessAddress = new BusinessAddressApi(v1Api);
    this.businessDetails = new BusinessDetailsApi(v1Api);
    this.businessMember = new BusinessMemberApi(v1Api);
    this.businessOwner = new BusinessOwnerApi(v1Api);
    this.capitalAccount = new CapitalAccountApi(v1Api);
    this.capitalDrawdownApproval = new CapitalDrawdownApprovalApi(v1Api);
    this.capitalTransactions = new CapitalTransactionsApi(v1Api);
    this.card = new CardApi(v1Api);
    this.categorizationRule = new CategorizationRuleApi(v1Api);
    this.categorizedResource = new CategorizedResourceApi(v1Api);
    this.counterpartyAliasManagement = new CounterpartyAliasManagementApi(v2Api);
    this.chargeCard = new ChargeCardApi(v1Api);
    this.chargeCardTransactions = new ChargeCardTransactionsApi(v1Api);
    this.counterpartyReport = new CounterpartyReportApi(v2Api);
    this.creditApplication = new CreditApplicationApi(v1Api);
    this.creditComparison = new CreditComparisonApi(v1Api);
    this.dailyPlReport = new DailyPlReportApi(v2Api);
    this.dailyShopifySummary = new DailyShopifySummaryApi(v1Api);
    this.debitCard = new DebitCardApi(v1Api);
    this.documentV2 = new DocumentV2Api(v2Api);
    this.documentV2Action = new DocumentV2ActionApi(v2Api);
    this.documents = new DocumentsApi(v1Api);
    this.generalPaymentMetadata = new GeneralPaymentMetadataApi(v1Api);
    this.insightsCategory = new InsightsCategoryApi(v2Api);
    this.insightsSheet = new InsightsSheetApi(v2Api);
    this.institution = new InstitutionApi(v1Api);
    this.intercom = new IntercomApi(v1Api);
    this.internationalBankAccount = new InternationalBankAccountApi(v1Api);
    this.lead = new LeadApi(v1Api);
    this.lineOfCredit = new LineOfCreditApi(v1Api);
    this.lineOfCreditBalanceRecovery = new LineOfCreditBalanceRecoveryApi(v1Api);
    this.lineOfCreditAgreement = new LineOfCreditAgreementApi(v1Api);
    this.lineOfCreditInterestFee = new LineOfCreditInterestFeeApi(v1Api);
    this.lineOfCreditTransactions = new LineOfCreditTransactionsApi(v1Api);
    this.payee = new PayeeApi(v1Api);
    this.payment = new PaymentApi(v1Api);
    this.paymentAction = new PaymentActionApi(v1Api);
    this.paymentV2 = new PaymentApiV2(v1Api);
    this.paymentDetails = new PaymentDetailsApi(v1Api);
    this.plaid = new PlaidApi(v1Api);
    this.referralLink = new ReferralLinkApi(v1Api);
    this.rutterBalance = new RutterBalanceApi(v1Api);
    this.rutterConnection = new RutterConnectionApi(v1Api);
    this.rutterPayout = new RutterPayoutApi(v1Api);
    this.shopifyBalance = new ShopifyBalanceApi(v1Api);
    this.shopifyConnection = new ShopifyConnectionApi(v1Api);
    this.shopifyPayouts = new ShopifyPayoutApi(v1Api);
    this.subcategories = new SubcategoryApi(v1Api);
    this.transaction = new TransactionApi(v1Api);
    this.transactionCategorizationMatcher = new TransactionCategorizationMatcherApi(v2Api);
    this.unitCoCustomerToken = new UnitCoCustomerTokenApi(v1Api);
    this.user = new UserApi(v1Api);
    this.userInvitation = new UserInvitationApi(v1Api);
    this.userNotificationSettings = new UserNotificationSettingsApi(v1Api);
    this.userRole = new UserRoleApi(v1Api);
    this.userRoleMembership = new UserRoleMembershipApi(v1Api);
    this.vendorList = new VendorListApi(v1Api);
  }
}
