import { ArrowLeft, PlusCircle, UserPlus, PencilSimpleLine } from "@phosphor-icons/react";
import { FC, FormEvent, useState } from "react";
import PayeeRep from "reps/PayeeRep";
import useUpdateBillMutation from "resources/bills/mutations/useUpdateBillMutation";
import useBill from "resources/bills/queries/useBill";
import useBillSuggestedPayee from "resources/bills/queries/useBillSuggestedPayee";
import useBillCreatePayeeFormDefaultPayeeName from "resources/bills/search-params/useBillCreatePayeeFormDefaultPayeeName";
import AchPaymentMethodFieldset from "resources/payee-payment-methods/components/AchPaymentMethodFieldset";
import DomesticWirePaymentMethodFieldset from "resources/payee-payment-methods/components/DomesticWirePaymentMethodFieldset";
import InternationalWirePaymentMethodRecoilFieldset from "resources/payee-payment-methods/components/InternationalWirePaymentMethodRecoilFieldset";
import useInternationalWirePaymentMethodRecoilHelpers from "resources/payee-payment-methods/components/InternationalWirePaymentMethodRecoilFieldset/useInternationalWirePaymentMethodRecoilHelpers";
import useAchPaymentMethodForm, {
  AchPaymentMethodFormInputs,
} from "resources/payee-payment-methods/forms/useAchPaymentMethodForm";
import useDomesticWirePaymentMethodForm, {
  DomesticWirePaymentMethodFormInputs,
} from "resources/payee-payment-methods/forms/useDomesticWirePaymentMethodForm";
import PayeeDetailsFieldset from "resources/payees/components/PayeeDetailsFieldset";
import usePayeeDetailsForm, {
  PayeeDetailsFormInputs,
} from "resources/payees/forms/usePayeeDetailsForm";
import useCreatePayeeMutation from "resources/payees/mutations/useCreatePayeeMutation";
import Avatar from "ui/data-display/Avatar";
import Banner from "ui/data-display/Banner";
import DetailsSidebarBody from "ui/data-display/DetailsSidebarBody";
import Divider from "ui/data-display/Divider";
import { notify } from "ui/feedback/Toast";
import Button from "ui/inputs/Button";
import Helper from "ui/inputs/Helper";
import SegmentControls from "ui/navigation/SegmentControls";
import Text from "ui/typography/Text";
import useSearchParamOption from "utils/searchParams/useSearchParamOption";
import { TabConfig } from "utils/tabs/useTabState";
import getObjectKeys from "utils/ts/getObjectKeys";

import styles from "./BillCreatePayeeForm.module.scss";

type Props = {
  billId: string;
  onBack: () => void;
  onSuccess: (createdPayee: PayeeRep.Complete) => void;
};

const BillCreatePayeeForm: FC<Props> = ({ onBack, onSuccess, billId }) => {
  const bill = useBill(billId, { required: true });
  const billInvoiceNumber = bill.invoiceNumber;
  const suggestedPayee = useBillSuggestedPayee(billId);
  const achSuggestions = suggestedPayee?.paymentMethods.find(
    (paymentMethod) => paymentMethod.paymentMethod === "Ach"
  );
  const wireSuggestions = suggestedPayee?.paymentMethods.find(
    (paymentMethod) => paymentMethod.paymentMethod === "Wire"
  );

  const tabs = {
    ach: {
      label: "ACH",
      displayAttentionBadge: Boolean(achSuggestions),
    },
    "domestic-wire": { label: "Wire", displayAttentionBadge: Boolean(wireSuggestions) },
    "international-wire": {
      label: "International wire",
    },
  } satisfies TabConfig;

  const [activeTab, setActiveTab] = useSearchParamOption(
    "payment-details",
    getObjectKeys(tabs),
    "ach"
  );

  const [defaultPayeeName] = useBillCreatePayeeFormDefaultPayeeName();

  const payeeDetailsForm = usePayeeDetailsForm({
    defaultValues: {
      payeeName: defaultPayeeName || suggestedPayee?.name || "",
      payeeEmail: suggestedPayee?.emailAddress || "",
      payeePhoneNumber: suggestedPayee?.phoneNumber || "",
    },
  });

  const achPaymentMethodForm = useAchPaymentMethodForm({
    suggestedPaymentMethod: achSuggestions ?? undefined,
  });

  const domesticWirePaymentMethodForm = useDomesticWirePaymentMethodForm({
    suggestedPaymentMethod: wireSuggestions ?? undefined,
  });

  const [internationalWireError, setInternationalWireError] = useState<string>();
  const { internationalWireTransferMethod, isValidInternational, hasInputValues } =
    useInternationalWirePaymentMethodRecoilHelpers({
      payeeGuid: undefined,
    });

  const { mutateAsync: updateBill, isPending: isUpdatingBill } = useUpdateBillMutation(billId, {
    onError: () => {
      notify("error", "Something went wrong while updating the bill. Please try again.");
    },
  });

  const { mutateAsync: createPayee, isPending } = useCreatePayeeMutation({
    onError: () => {
      notify("error", "Something went wrong while creating the payee. Please try again.");
    },
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payeeDetailsFormData = await new Promise<PayeeDetailsFormInputs>((resolve, reject) => {
      payeeDetailsForm.handleSubmit(resolve, reject)(e);
    });

    const transferMethodBody = await (async () => {
      if (activeTab === "ach") {
        const achPaymentMethodFormData = await new Promise<AchPaymentMethodFormInputs>(
          (resolve, reject) => {
            achPaymentMethodForm.handleSubmit(resolve, reject)(e);
          }
        );
        return {
          achTransferMethod: {
            routingNumber: achPaymentMethodFormData.routingNumber,
            accountNumber: achPaymentMethodFormData.accountNumber,
          } satisfies PayeeRep.AchTransferMethod,
        };
      }
      if (activeTab === "domestic-wire") {
        const domesticWirePaymentMethodData =
          await new Promise<DomesticWirePaymentMethodFormInputs>((resolve, reject) => {
            domesticWirePaymentMethodForm.handleSubmit(
              // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
              (data) => resolve(data as DomesticWirePaymentMethodFormInputs),
              reject
            )(e);
          });

        return {
          wireTransferMethod: {
            routingNumber: domesticWirePaymentMethodData.routingNumber,
            accountNumber: domesticWirePaymentMethodData.accountNumber,
          } satisfies PayeeRep.WireTransferMethod,
          // TODO(alex): HB-5275 This code is not good, but need to ship and it works for now.
          address: {
            addressLine1: domesticWirePaymentMethodData.address.addressLine1?.value ?? "",
            addressLine2: domesticWirePaymentMethodData.address.addressLine2 || undefined,
            city: domesticWirePaymentMethodData.address.city,
            state: domesticWirePaymentMethodData.address.state?.value ?? "",
            zipCode: domesticWirePaymentMethodData.address.zipCode,
            country: domesticWirePaymentMethodData.address.country?.value ?? "",
          } satisfies PayeeRep.Address,
        };
      }
    })();

    // HACK(alex): We're hacking this form to work with recoil state for international wire details, so we have to trigger form validation manually.
    if (hasInputValues && !isValidInternational) {
      return setInternationalWireError("Missing fields.");
    }

    const createdPayee = await createPayee({
      name: payeeDetailsFormData.payeeName,
      emailAddress: payeeDetailsFormData.payeeEmail || undefined,
      phone: payeeDetailsFormData.payeePhoneNumber || undefined,
      ...transferMethodBody,
      ...internationalWireTransferMethod,
    });

    await updateBill({
      payeeGuid: createdPayee.guid,
    });

    onSuccess(createdPayee);
    notify("success", "Payee created");
  };

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <DetailsSidebarBody
        header={
          <DetailsSidebarBody.Header>
            <Button variant="ghost" isSquare onClick={onBack}>
              <ArrowLeft size={24} weight="light" />
            </Button>

            <Avatar icon={<UserPlus />} size={44} color="grey" />

            <Text weight="medium" size={16} as="h3">
              Create payee
            </Text>
          </DetailsSidebarBody.Header>
        }
        main={
          <DetailsSidebarBody.Main>
            <DetailsSidebarBody.Section>
              {suggestedPayee && (
                <Banner
                  icon={<PencilSimpleLine />}
                  body={
                    <Text as="p" size={14}>
                      We’ve pre-filled some of the payee info from{" "}
                      {billInvoiceNumber ? (
                        <>
                          invoice <Text as="strong">{billInvoiceNumber}</Text>
                        </>
                      ) : (
                        <>the invoice</>
                      )}
                      .
                    </Text>
                  }
                  className={styles.suggestedPayeeBanner}
                />
              )}

              <PayeeDetailsFieldset form={payeeDetailsForm} />
            </DetailsSidebarBody.Section>

            <Divider margin={0} />

            <DetailsSidebarBody.Section>
              <Text size={14} weight="bold">
                Payment details
              </Text>

              <SegmentControls
                size="md"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabs={tabs}
                className={styles.segmentControls}
              />

              {activeTab === "ach" && <AchPaymentMethodFieldset form={achPaymentMethodForm} />}
              {activeTab === "domestic-wire" && (
                <DomesticWirePaymentMethodFieldset form={domesticWirePaymentMethodForm} />
              )}
              {activeTab === "international-wire" && (
                <div>
                  {internationalWireError && (
                    <Helper
                      icon={<Helper.Icon variant="error" />}
                      className={styles.internationalWireErrorHelperWrapper}
                    >
                      {internationalWireError}
                    </Helper>
                  )}
                  <InternationalWirePaymentMethodRecoilFieldset />
                </div>
              )}
            </DetailsSidebarBody.Section>
          </DetailsSidebarBody.Main>
        }
        footer={
          <DetailsSidebarBody.Footer className={styles.footer}>
            <Button type="submit" variant="primary" isLoading={isPending || isUpdatingBill}>
              <PlusCircle size={20} /> Create payee
            </Button>

            <Button variant="ghost" onClick={onBack}>
              Cancel
            </Button>
          </DetailsSidebarBody.Footer>
        }
      />
    </form>
  );
};

export default BillCreatePayeeForm;
