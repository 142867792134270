import { useSuspenseQuery } from "@tanstack/react-query";
import useHighbeamApi from "utils/customHooks/useHighbeamApi";
import useQueryOptions from "utils/react-query/useQueryOptions";

import { BILLS_QUERY_KEY } from "./useRefreshBillsQueries";

const useBillSuggestedPayeeQueryOptions = (billId: string) => {
  const highbeamApi = useHighbeamApi();

  return useQueryOptions({
    queryKey: [BILLS_QUERY_KEY, "suggestedPayee", { billId }],
    queryFn: () => {
      return highbeamApi.billSuggestedPayee.get(billId);
    },
  });
};

const useBillSuggestedPayee = (billId: string) => {
  const { data } = useSuspenseQuery(useBillSuggestedPayeeQueryOptions(billId));

  return data;
};

export default useBillSuggestedPayee;
