import { zodResolver } from "@hookform/resolvers/zod";
import dayjs, { Dayjs } from "dayjs";
import { useForm } from "react-hook-form";
import isClosedBooksMonthRecentEnough, {
  REQUIRED_CLOSED_BOOKS_DAYS,
} from "resources/credit-application/utils/isClosedBooksMonthRecentEnough";
import isFirstSaleMonthEarlyEnough from "resources/credit-application/utils/isFirstSaleMonthEarlyEnough";
import { z } from "zod";

const schema = z.object({
  firstSale: z
    .custom<Dayjs>((value) => Boolean(value), "Please select the date of your first sale.")
    .refine((value) => dayjs.isDayjs(value) && isFirstSaleMonthEarlyEnough(value), {
      message:
        "Your business needs at least one year of sales history in order to be eligible for Highbeam Capital.",
    }),
  closedBooks: z
    .custom<Dayjs>(
      (value) => Boolean(value),
      "Please select a date when you last closed your books."
    )
    .refine((value) => dayjs(value).isValid() && isClosedBooksMonthRecentEnough(value), {
      message: `We don't accept financials older than ${dayjs()
        .subtract(REQUIRED_CLOSED_BOOKS_DAYS, "day")
        .format("MMMM YYYY")}.`,
    }),
});

export type ApplicationRequirementsFormInputs = z.infer<typeof schema>;

const useApplicationRequirementsForm = () => {
  return useForm<ApplicationRequirementsFormInputs>({
    resolver: zodResolver(schema),
  });
};

export default useApplicationRequirementsForm;
