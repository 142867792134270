import classNames from "classnames";
import { FC, ReactNode } from "react";

import styles from "./Teaser.module.scss";
import TeaserHero from "./TeaserHero";
import TeaserMainSection from "./TeaserMainSection";

type Props = {
  className?: string;
  children: ReactNode;
};

const Teaser: FC<Props> = ({ className, children }) => (
  <div className={classNames(styles.container, className)}>{children}</div>
);

export default Object.assign(Teaser, {
  Hero: TeaserHero,
  MainSection: TeaserMainSection,
});
