import SuggestedPayeeRep from "reps/SuggestedPayeeRep";

import HighbeamBaseApi from "./HighbeamBaseApi";

export default class BillSuggestedPayeeApi {
  private readonly api: HighbeamBaseApi;

  constructor(api: HighbeamBaseApi) {
    this.api = api;
  }

  async get(billId: string): Promise<SuggestedPayeeRep.Complete | null> {
    const url = `/accounts-payable/bills/${billId}/suggested-payee`;
    return await this.api.get<SuggestedPayeeRep.Complete>(url);
  }
}
