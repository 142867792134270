import DashboardLayout from "components/layouts/Dashboard";
import OnboardingRouter from "pages/Onboarding/OnboardingRouter";
import SettingsUsersPage from "pages/settings/SettingsUsersPage";
import { Navigate, Route, Routes } from "react-router-dom";

const NonOnboardedRouter = () => (
  <Routes>
    <Route path="/onboarding/*" element={<OnboardingRouter />} />
    <Route
      path="/settings/*"
      element={
        <DashboardLayout>
          <Routes>
            <Route path="/users" element={<SettingsUsersPage />} />
            <Route path="*" element={<Navigate replace to="/onboarding" />} />
          </Routes>
        </DashboardLayout>
      }
    />
    <Route path="*" element={<Navigate replace to="/onboarding" />} />
  </Routes>
);

export default NonOnboardedRouter;
