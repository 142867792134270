import classNames from "classnames";

import styles from "./Card.module.scss";

export type CardShadow = "none" | "xs" | "sm" | "md" | "lg" | "xl";

type Props = JSX.IntrinsicElements["div"] & {
  shadow?: CardShadow;
  dashed?: boolean;
};

const Card: React.FC<Props> = ({ children, className, shadow = "md", dashed, ...divProps }) => (
  <div
    className={classNames(
      className,
      styles.card,
      styles[`shadow--${shadow}`],
      dashed && styles.dashed
    )}
    {...divProps}
  >
    {children}
  </div>
);

export default Card;
