import { ReactElement, ReactNode, useState } from "react";

export type TabConfigTab = {
  icon?: ReactElement<{ size: number }>;
  label: ReactNode;
  count?: number;
  displayAttentionBadge?: boolean;
};

export type TabConfig = Record<string, TabConfigTab>;

const useTabState = <TTabs extends TabConfig>(
  tabs: TTabs,
  initialTab: keyof TTabs = Object.keys(tabs)[0]
) => {
  const [activeTab, setActiveTab] = useState<keyof TTabs>(initialTab);
  return [activeTab, setActiveTab] as const;
};

export default useTabState;
