import {
  CalendarBlank,
  ChartLineUp,
  Check,
  Clock,
  DotsThreeCircle,
  Question,
  XCircle,
} from "@phosphor-icons/react";
import DashboardPage from "components/layouts/DashboardPage";
import dayjs from "dayjs";
import { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import CreditApplicationRep from "reps/CreditApplicationRep";
import useBusinessAddress from "resources/business-details/queries/useBusinessAddress";
import useBusiness from "resources/business/queries/useBusiness";
import CapitalFaqs from "resources/capital/components/CapitalFaq";
import useMostRecentCreditApplication from "resources/credit-application/queries/useMostRecentCreditApplication";
import getHasPassedApplicationRequirements from "resources/credit-application/utils/hasPassedApplicationRequirements";
import colors from "styles/colors";
import Teaser from "ui/data-display/Teaser";
import Button from "ui/inputs/Button";
import Heading2 from "ui/typography/Heading2";
import Text from "ui/typography/Text";
import { states, supportedStatesForCapital } from "utils/address";
import useFeatureFlag from "utils/customHooks/useFeatureFlag";
import useIsAllowedToAcceptLineOfCredit from "utils/permissions/useIsAllowedToAcceptLineOfCredit";
import useSearchParamBoolean from "utils/searchParams/useSearchParamBoolean";

import CapitalHeaderTitle from "../../components/CapitalHeaderTitle";

import ApplicationRequirementsModal from "./ApplicationRequirementsModal";
import CapitalTeaserV2 from "./assets/capital-teaser-v2.svg";
import CapitalProductsSection from "./CapitalProductsSection";
import { useUpdateBusinessAddressModal } from "./state/UpdateBusinessAddressModalState";
import UpdateBusinessAddressModal from "./UpdateBusinessAddressModal";
import styles from "./UpsellCapitalView.module.scss";

const StartApplicationButton = () => {
  const isAllowedToAcceptLineOfCredit = useIsAllowedToAcceptLineOfCredit();
  const mostRecentCreditApplication = useMostRecentCreditApplication();

  const [isApplicationRequirementsModalOpen, setIsApplicationRequirementsModalOpen] =
    useSearchParamBoolean("start-application", false);

  const isApplicationRequirementModalFlagEnabled = useFeatureFlag(
    "CREDIT_APPLICATION_REQUIREMENTS_MODAL"
  );

  const hasPassedApplicationRequirements = useMemo(() => {
    return mostRecentCreditApplication
      ? getHasPassedApplicationRequirements(mostRecentCreditApplication)
      : false;
  }, [mostRecentCreditApplication]);

  return (
    <>
      {isApplicationRequirementModalFlagEnabled && isApplicationRequirementsModalOpen && (
        <ApplicationRequirementsModal
          onClose={() => setIsApplicationRequirementsModalOpen(false)}
        />
      )}

      <div className={styles.teaserButtonLink}>
        {isApplicationRequirementModalFlagEnabled ? (
          <>
            {hasPassedApplicationRequirements ? (
              <Link to={"/capital/application/connect-stores"}>
                <Button
                  className={styles.teaserButton}
                  variant="primary"
                  disabled={!isAllowedToAcceptLineOfCredit}
                  tooltip={
                    !isAllowedToAcceptLineOfCredit && (
                      <Text size={14}>
                        You don’t have permission to apply for Highbeam Capital.
                      </Text>
                    )
                  }
                >
                  Continue application
                </Button>
              </Link>
            ) : (
              <Button
                className={styles.teaserButton}
                variant="primary"
                onClick={() => {
                  setIsApplicationRequirementsModalOpen(true);
                }}
                disabled={!isAllowedToAcceptLineOfCredit}
                tooltip={
                  !isAllowedToAcceptLineOfCredit && (
                    <Text size={14}>You don’t have permission to apply for Highbeam Capital.</Text>
                  )
                }
              >
                Start application
              </Button>
            )}
          </>
        ) : (
          <Link to={"/capital/application/connect-stores"}>
            <Button
              className={styles.teaserButton}
              variant="primary"
              disabled={!isAllowedToAcceptLineOfCredit}
              tooltip={
                !isAllowedToAcceptLineOfCredit && (
                  <Text size={14}>You don’t have permission to apply for Highbeam Capital.</Text>
                )
              }
            >
              {mostRecentCreditApplication?.state === "New"
                ? "Continue application"
                : "Start application"}
            </Button>
          </Link>
        )}
        <div className={styles.teaserButtonTimeInfo}>
          <Clock color={colors.grey[600]} size={24} weight="thin" />
          <Text size={14} color={colors.grey[600]}>
            Takes 5 minutes
          </Text>
        </div>
      </div>
    </>
  );
};

const ReviewProgressSection = () => {
  return (
    <div className={styles.progressTracker}>
      <div className={styles.step}>
        <div className={styles.stepNumberFirst}>
          <Check size={12} color={colors.white} />
        </div>
        <div className={styles.dividerPurple} />
        <div className={styles.stepNumberFirst}>2</div>
        <div className={styles.dividerStripes} />
        <div className={styles.stepNumberLast}>3</div>
      </div>

      <div className={styles.step}>
        <Text size={14} color={colors.grey[500]}>
          Applied
        </Text>
        <Text size={14} color={colors.purple[500]} weight="bold">
          In review
        </Text>
        <Text size={14} color={colors.grey[500]}>
          Offer
        </Text>
      </div>
    </div>
  );
};

const ReviewInProgressTeaser = () => (
  <Teaser className={styles.teaserContainerReview}>
    <Teaser.MainSection className={styles.teaserOutterSection}>
      <div className={styles.teaserMainSectionReview}>
        <div className={styles.inProgress}>
          <DotsThreeCircle color={colors.purple[500]} size={20} weight="thin" />
          <Text size={14} color={colors.purple[500]}>
            Review in progress
          </Text>
        </div>
        <div className={styles.teaserText}>
          <Heading2>We’re reviewing your application</Heading2>
          <Text size={16} color={colors.grey[500]}>
            We’ll reach out with any follow up questions. Otherwise, we’ll email you with your offer
            in <strong>8 - 10 business days</strong>.
          </Text>
        </div>
        <ReviewProgressSection />
      </div>
    </Teaser.MainSection>
  </Teaser>
);

export const UnsupportedStateTeaser = () => {
  const { open: openUpdateBusinessAddressModal } = useUpdateBusinessAddressModal();
  const { stateOfIncorporation } = useBusiness();

  const { line1, city, state, postalCode } = useBusinessAddress();
  const fullAddress = `${line1}, ${city}, ${state} ${postalCode}`;
  const stateFullName = states[state];
  const allowCaCreditApplications = useFeatureFlag("ALLOW_CA_CREDIT_APPLICATIONS");

  return (
    <>
      <UpdateBusinessAddressModal />
      <Teaser className={styles.teaserContainerUnsupportedLocation}>
        <Teaser.MainSection className={styles.teaserOutterSection}>
          <div className={styles.teaserMainSectionReview}>
            <div className={styles.unsupportedLocation}>
              <XCircle color={colors.orange[700]} size={20} weight="thin" />
              <Text size={14} color={colors.orange[700]}>
                Unsupported location
              </Text>
            </div>
            <div className={styles.teaserText}>
              <Heading2>Sorry, we can’t support customers in {stateFullName}.</Heading2>
              <Text size={16} color={colors.grey[500]}>
                Your physical address <strong>{fullAddress}</strong> is in an unsupported state.
                We’ll email you when we begin supporting capital customers in {stateFullName}.
              </Text>
            </div>
            <div className={styles.unsupportedLocationButtons}>
              <Button
                variant="tertiary"
                className={styles.teaserButton}
                onClick={() => {
                  Intercom(
                    "showNewMessage",
                    "Hi, my physical address is unsupported. Can you please help me understand why?"
                  );
                }}
              >
                <Question size={24} />
                Learn why
              </Button>
              {(allowCaCreditApplications || stateOfIncorporation !== "CA") && (
                <Button variant="plain" onClick={() => openUpdateBusinessAddressModal({})}>
                  I have another physical address
                </Button>
              )}
            </div>
          </div>
        </Teaser.MainSection>
      </Teaser>
    </>
  );
};

type NotApprovedTeaserProps = {
  mostRecentCreditApplication: CreditApplicationRep.Complete;
};

const NotApprovedTeaser: FC<NotApprovedTeaserProps> = ({ mostRecentCreditApplication }) => {
  const submittedAtDate = mostRecentCreditApplication.submittedAt
    ? dayjs(mostRecentCreditApplication.submittedAt)
    : null;
  const reapplyDate = submittedAtDate ? submittedAtDate.add(60, "day") : null;
  const isPastReapplyDate = reapplyDate && reapplyDate.isBefore(dayjs());
  const intercomMessage = isPastReapplyDate
    ? "Hi, I would like to re-apply for Highbeam Capital."
    : "Hi, my credit application was not approved. Can you please help me understand why?";

  return (
    <Teaser className={styles.teaserContainerRejected}>
      <Teaser.MainSection className={styles.teaserOutterSection}>
        <div className={styles.rejected}>
          <XCircle color={colors.red[500]} size={20} weight="regular" />
          <Text size={14} color={colors.red[500]} weight="medium">
            Application not approved
          </Text>
        </div>
        <div className={styles.teaserText}>
          <Heading2>Sorry, we can’t provide you with a capital offer at this time</Heading2>
          {reapplyDate && (
            <div className={styles.teaserSubText}>
              <CalendarBlank size={24} color={colors.grey[900]} weight="light" />
              <Text size={16} color={colors.grey[900]}>
                You can re-apply any time after{" "}
                <strong>{reapplyDate.format("MMMM DD, YYYY")}</strong>
              </Text>
            </div>
          )}
        </div>
        <Button
          variant="tertiary"
          className={styles.teaserButton}
          onClick={() => {
            Intercom("showNewMessage", intercomMessage);
          }}
        >
          <Question size={24} />
          {isPastReapplyDate ? "Get in touch" : "Learn why"}
        </Button>
      </Teaser.MainSection>
    </Teaser>
  );
};

type ApplicationInProgressTeaserProps = {
  creditApplicationState: CreditApplicationRep.State;
};

const ApplicationInProgressTeaser: FC<ApplicationInProgressTeaserProps> = ({
  creditApplicationState,
}) => {
  return (
    <Teaser className={styles.teaserContainerNew}>
      <Teaser.MainSection className={styles.teaserOutterSection}>
        <div className={styles.teaserMainSectionNew}>
          {creditApplicationState === "New" ? (
            <div className={styles.inProgress}>
              <DotsThreeCircle color={colors.purple[500]} size={20} weight="thin" />
              <Text size={14} color={colors.purple[500]}>
                Application in progress
              </Text>
            </div>
          ) : (
            <div className={styles.chartUpIcon}>
              <ChartLineUp color={colors.purple[500]} size={32} weight="thin" />
            </div>
          )}
          <div>
            <Heading2>Grow with Highbeam Capital</Heading2>
            <Text size={16} color={colors.grey[500]}>
              Apply now for access to Highbeam Capital. During your application we’ll ask you to
              share up-to-date financials, connect your stores, bank accounts, and accounting
              software.
            </Text>
          </div>
          <StartApplicationButton />
        </div>
      </Teaser.MainSection>
      <img
        src={CapitalTeaserV2}
        alt="Capital teaser"
        title="Capital teaser"
        className={styles.heroImg}
      />
    </Teaser>
  );
};

const UpsellCapitalTeaser: FC = () => {
  const mostRecentCreditApplication = useMostRecentCreditApplication();
  const { state } = useBusinessAddress();
  const allowCaCreditApplications = useFeatureFlag("ALLOW_CA_CREDIT_APPLICATIONS");

  switch (mostRecentCreditApplication?.state) {
    case "RejectionNotified":
    case "Rejected":
      return <NotApprovedTeaser mostRecentCreditApplication={mostRecentCreditApplication} />;
    case undefined:
    case "New":
    case "OfferRejected":
    case "OfferExpired":
    case "Canceled":
      return (
        <ApplicationInProgressTeaser
          creditApplicationState={mostRecentCreditApplication?.state ?? "New"}
        />
      );
    case "Submitted":
      const isSupportedState =
        state in supportedStatesForCapital || (allowCaCreditApplications && state === "CA");
      if (!isSupportedState) {
        return <UnsupportedStateTeaser />;
      }
      return <ReviewInProgressTeaser />;
    case "Activated":
    case "ApprovalProcessed":
    case "Approved":
    case "OfferAccepted":
    case "OfferNotified":
    case "PayoutsConnected":
      return <ReviewInProgressTeaser />;
  }
};

const UpsellCapitalView = () => {
  return (
    <>
      <DashboardPage.Header>
        <CapitalHeaderTitle />
      </DashboardPage.Header>

      <DashboardPage.Section>
        <UpsellCapitalTeaser />
      </DashboardPage.Section>

      <DashboardPage.Section>
        <DashboardPage.Section.Header heading="Capital products" />
        <CapitalProductsSection />
      </DashboardPage.Section>

      <DashboardPage.Section>
        <CapitalFaqs />
      </DashboardPage.Section>
    </>
  );
};

export default UpsellCapitalView;
