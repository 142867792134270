import { ArrowFatLinesDown, ArrowFatLinesUp, DotsThree, Trash } from "@phosphor-icons/react";
import highYieldIcon from "assets/high-yield-icon-purple.svg";
import ApyTooltipContent from "components/Accounts/InterestTier/ApyTooltipContent";
import YieldInfoTooltipContent from "components/Accounts/InterestTier/YieldEarnedTooltipContent";
import IconWithTooltip from "components/common/IconWithTooltip";
import DashboardHeader from "components/layouts/Dashboard/DashboardHeader/DashboardHeader";
import DashboardPage from "components/layouts/DashboardPage";
import { Suspense } from "react";
import { Link, Navigate } from "react-router-dom";
import BankAccountRep from "reps/BankAccountRep";
import HighYieldTeaser from "resources/bank-accounts/components/HighYieldTeaser";
import isValidHighYieldAccountToClose from "resources/bank-accounts/utils/isValidHighYieldAccountToClose";
import CashDisplay from "ui/data-display/money/CashDisplay";
import SummaryArray from "ui/data-display/SummaryArray";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import Button from "ui/inputs/Button";
import Breadcrumbs from "ui/navigation/Breadcrumbs";
import ButtonArray from "ui/navigation/ButtonArray";
import Menu from "ui/overlay/Menu";
import Text from "ui/typography/Text";
import useIsAllowedToCloseBankAccount from "utils/permissions/useIsAllowedToCloseBankAccount";

import CloseBankAccountModal from "../CloseBankAccountModal";
import { useCloseBankAccountModal } from "../CloseBankAccountModal/state/closeBankAccountModalState";

import HighYieldInterestYtd from "./HighYieldInterestYtd";
import styles from "./HighYieldPage.module.scss";
import HighYieldTransactions from "./HighYieldTransactions";

type Props = {
  account: BankAccountRep.Complete;
};

const HighYieldPage: React.FC<Props> = ({ account }) => {
  const isAllowedToCloseBankAccount = useIsAllowedToCloseBankAccount();
  const { open: openCloseBankAccountModal } = useCloseBankAccountModal();

  return account.highbeamType.name === "HighYield" ? (
    <>
      <DashboardHeader>
        <Breadcrumbs>
          <Breadcrumbs.Item to="/accounts">Accounts</Breadcrumbs.Item>
          <Breadcrumbs.CurrentItem>High yield</Breadcrumbs.CurrentItem>
        </Breadcrumbs>
      </DashboardHeader>

      <DashboardPage>
        <DashboardPage.Header
          actions={
            <>
              <ButtonArray alignment="horizontal">
                <Link to="/transfer-money" state={{ toAccountGuid: account.guid }}>
                  <ButtonArray.Button variant="secondary">
                    <ArrowFatLinesDown size={14} />
                    Deposit
                  </ButtonArray.Button>
                </Link>
                <Link to="/transfer-money" state={{ fromAccountGuid: account.guid }}>
                  <ButtonArray.Button variant="secondary" disabled={account.availableBalance === 0}>
                    <ArrowFatLinesUp size={14} />
                    Withdraw
                  </ButtonArray.Button>
                </Link>
              </ButtonArray>
              {isAllowedToCloseBankAccount && isValidHighYieldAccountToClose(account) && (
                <Menu
                  button={
                    <Button variant="tertiary" isSquare>
                      <DotsThree size={16} />
                    </Button>
                  }
                >
                  <Menu.Item
                    variant="danger"
                    icon={<Trash size={16} />}
                    onClick={() => {
                      openCloseBankAccountModal({
                        bankAccount: account,
                      });
                    }}
                  >
                    <Text size={14}>Close account</Text>
                  </Menu.Item>
                </Menu>
              )}
            </>
          }
        >
          <DashboardPage.Header.BackCaret to="/accounts" />
          <DashboardPage.Header.IconTile
            icon={<img alt={"High yield account icon"} src={highYieldIcon} />}
          />
          <DashboardPage.Header.Title>{account.name}</DashboardPage.Header.Title>
        </DashboardPage.Header>

        {account.availableBalance === 0 && (
          <DashboardPage.Section>
            <HighYieldTeaser account={account} />
          </DashboardPage.Section>
        )}

        <DashboardPage.Section>
          <SummaryArray>
            <SummaryArray.Item>
              <Text size={14}>Account balance</Text>
              <CashDisplay cents={account.availableBalance} />
            </SummaryArray.Item>
            <SummaryArray.Item>
              <div className={styles.interestSummaryHeader}>
                <Text size={14}>Current APY</Text>
                <IconWithTooltip content={<ApyTooltipContent account={account} />} />
              </div>
              <Text size={28} weight="medium">
                {(account.depositProduct.interestBps / 100).toFixed(1)}%
              </Text>
            </SummaryArray.Item>
            <SummaryArray.Item>
              <div className={styles.interestSummaryHeader}>
                <Text size={14}>Accrued interest (YTD)</Text>
                <IconWithTooltip content={<YieldInfoTooltipContent />} />
              </div>
              <Suspense fallback={<AnimatedSpinner />}>
                <HighYieldInterestYtd accountGuid={account.guid} />
              </Suspense>
            </SummaryArray.Item>
          </SummaryArray>
        </DashboardPage.Section>

        <DashboardPage.Section>
          <DashboardPage.Section.Header>
            <DashboardPage.Section.HeaderHeading>Transactions</DashboardPage.Section.HeaderHeading>
          </DashboardPage.Section.Header>
          <DashboardPage.Section.Body>
            <HighYieldTransactions account={account} />
          </DashboardPage.Section.Body>
        </DashboardPage.Section>
      </DashboardPage>

      <CloseBankAccountModal />
    </>
  ) : (
    <Navigate to="/accounts" />
  );
};

export default HighYieldPage;
