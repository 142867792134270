import classNames from "classnames";
import {
  useState,
  useRef,
  ReactElement,
  ForwardedRef,
  useImperativeHandle,
  RefObject,
} from "react";
import Select, {
  components as defaultComponents,
  GroupBase,
  Props as SelectProps,
  SelectInstance,
  OptionProps,
  MenuProps,
} from "react-select";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import AnimatedSpinner from "ui/feedback/AnimatedSpinner";
import useKeyboardEvent from "utils/customHooks/useKeyboardEvent";
import forwardRefPreserveGenerics from "utils/react-helpers/forwardRefPreserveGenerics";

import InputWrapper, { SharedInputProps } from "../InputWrapperV2";

import styles from "./Dropdown.module.scss";
import DropdownClearIndicator from "./DropdownClearIndicator";
import DropdownCreateLabel from "./DropdownCreateLabel";
import DropdownIndicator from "./DropdownIndicator";
import DropdownMenu from "./DropdownMenu";
import DropdownOption from "./DropdownOption";

/**
 * 🚧 Under construction
 *
 * NB(alex):
 * • This implementation is much closer to how `react-select` is intended to be used, but still has a long way to go.
 * • This is meant to preserve most functionality from our original `Dropdown` component but may be missing features still.
 * • I've intentionally removed the `menuAppend` / `menuPrepend` props for now, but we will probably want them eventually.
 * • `isMulti` is a valid prop but not actually supported yet.
 */

type BaseProps<
  TOption,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> = SharedInputProps &
  Omit<SelectProps<TOption, TIsMulti, TGroup>, "isDisabled"> &
  // NB(alex): Should probably check `extends { onCreateOption } ? ...` to conditionally add these props.
  CreatableProps<TOption, TIsMulti, TGroup>;

export type DropdownProps<
  TOption,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> = BaseProps<TOption, TIsMulti, TGroup> & {
  value: TOption | null;
  options: TOption[] | undefined;
  renderOption?: (props: OptionProps<TOption, TIsMulti, TGroup>) => ReactElement | null;
  renderMenu?: (
    props: MenuProps<TOption, TIsMulti, TGroup>,
    selectRef: RefObject<SelectInstance<TOption, TIsMulti, TGroup>> // Useful for unsetting the focused element.
  ) => ReactElement | null;
  hideCaret?: boolean;
  hideClearIndicator?: boolean;
  autoWidth?: boolean;
};

const noStyleHandler: ProxyHandler<object> = { get: () => () => ({}) };
const resetStyles = new Proxy({}, noStyleHandler);

const Dropdown = <
  TOption,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>(
  {
    autoFocus,
    autoWidth,
    className,
    disabled,
    id,
    isLoading,
    label,
    placeholder,
    startAdornment,
    showErrorOutline,
    value,
    onBlur,
    onCreateOption,
    onFocus,
    renderOption,
    renderMenu,
    hideCaret = isLoading || disabled,
    hideClearIndicator,
    components,
    ...selectProps
  }: DropdownProps<TOption, TIsMulti, TGroup>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const selectRef = useRef<SelectInstance<TOption, TIsMulti, TGroup>>(null);

  useImperativeHandle(ref, () => {
    // NB(alex): non-null assertion fixes ts error. Feel free to modify if there is a better way to handle this without having to specify default values for 350+ input fields.
    return selectRef.current?.inputRef!;
  });

  // Blur when pressing `Escape`. Seems like this should be a default...
  useKeyboardEvent(
    (e) => e.key === "Escape",
    () => selectRef.current?.blur(),
    [selectRef]
  );

  // TODO(alex): can we use selectRef to determine if focused?
  const [showFocusOutline, setShowFocusOutline] = useState(autoFocus ?? false);
  const SelectTag = onCreateOption ? CreatableSelect : Select;
  const shouldShrinkLabel =
    Boolean(label) && Boolean(value || placeholder || showFocusOutline || selectProps.inputValue);

  return (
    <InputWrapper
      className={className}
      disabled={disabled}
      inputId={id}
      label={label}
      startAdornment={startAdornment}
      shouldShrinkLabel={shouldShrinkLabel}
      showErrorOutline={showErrorOutline}
      showFocusOutline={showFocusOutline}
    >
      <SelectTag
        className={classNames(styles.container, {
          [styles.disabled]: disabled,
          [styles.focused]: shouldShrinkLabel,
          [styles["auto-width"]]: autoWidth,
        })}
        classNamePrefix={"dropdown-v2"}
        ref={selectRef}
        styles={resetStyles}
        onFocus={(e) => {
          setShowFocusOutline(true);
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setShowFocusOutline(false);
          onBlur?.(e);
        }}
        components={{
          ...defaultComponents,
          DropdownIndicator: (props) => (hideCaret ? null : <DropdownIndicator {...props} />),
          ClearIndicator: hideClearIndicator ? () => null : DropdownClearIndicator,
          Option: renderOption ?? DropdownOption,
          Menu: renderMenu
            ? (menuProps) => renderMenu(menuProps, selectRef)
            : (menuProps) => <DropdownMenu {...menuProps} selectRef={selectRef} />,
          LoadingIndicator: () => <AnimatedSpinner size={18} />,
          NoOptionsMessage: () => null,
          LoadingMessage: () => null,
          ...components,
        }}
        autoFocus={autoFocus}
        id={id}
        isLoading={isLoading}
        placeholder={placeholder ?? ""} // Defaults to `Select...` if `undefined`.
        value={value}
        onCreateOption={onCreateOption}
        backspaceRemovesValue // NB(alex): I think we want this to default to `true` but not 100% sure about this
        isDisabled={disabled}
        tabSelectsValue={false}
        {...selectProps}
      />
    </InputWrapper>
  );
};

export default Object.assign(forwardRefPreserveGenerics(Dropdown), {
  ...defaultComponents,
  CreateLabel: DropdownCreateLabel,
  Option: DropdownOption,
  Menu: DropdownMenu,
});

// There's an annoying bug with `react-select` where although it supports string options, it breaks the search, so this helper is needed for converting string values into options.
export const convertStringValueToDropdownOption = <TValue extends string = string>(
  value: TValue
): { label: string; value: TValue } => {
  return { label: value, value: value };
};
