import dayjs, { Dayjs } from "dayjs";

export const REQUIRED_ACTIVE_BUSINESS_DAYS = 365;

const isFirstSaleMonthEarlyEnough = (inputDate: Dayjs): boolean => {
  const now = dayjs();
  const earliestValidDate = now.subtract(REQUIRED_ACTIVE_BUSINESS_DAYS, "day");

  // Check if the input date's month is the same or before the expected date's month
  return inputDate.isBefore(earliestValidDate.add(1, "month"), "month");
};

export default isFirstSaleMonthEarlyEnough;
